exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-farmersmarket-clothesreuse-js": () => import("./../../../src/pages/farmersmarket/clothesreuse.js" /* webpackChunkName: "component---src-pages-farmersmarket-clothesreuse-js" */),
  "component---src-pages-farmersmarket-foodscraps-js": () => import("./../../../src/pages/farmersmarket/foodscraps.js" /* webpackChunkName: "component---src-pages-farmersmarket-foodscraps-js" */),
  "component---src-pages-farmersmarket-parkeast-js": () => import("./../../../src/pages/farmersmarket/parkeast.js" /* webpackChunkName: "component---src-pages-farmersmarket-parkeast-js" */),
  "component---src-pages-farmersmarket-wastemanagement-js": () => import("./../../../src/pages/farmersmarket/wastemanagement.js" /* webpackChunkName: "component---src-pages-farmersmarket-wastemanagement-js" */),
  "component---src-pages-farmersmarket-whiteplains-js": () => import("./../../../src/pages/farmersmarket/whiteplains.js" /* webpackChunkName: "component---src-pages-farmersmarket-whiteplains-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kidszone-js": () => import("./../../../src/pages/kidszone.js" /* webpackChunkName: "component---src-pages-kidszone-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-stories-diy-spinner-js": () => import("./../../../src/pages/stories/diy-spinner.js" /* webpackChunkName: "component---src-pages-stories-diy-spinner-js" */),
  "component---src-pages-stories-jellyfish-js": () => import("./../../../src/pages/stories/jellyfish.js" /* webpackChunkName: "component---src-pages-stories-jellyfish-js" */),
  "component---src-pages-stories-tote-bag-js": () => import("./../../../src/pages/stories/tote-bag.js" /* webpackChunkName: "component---src-pages-stories-tote-bag-js" */),
  "component---src-pages-vendor-js": () => import("./../../../src/pages/vendor.js" /* webpackChunkName: "component---src-pages-vendor-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */),
  "component---src-pages-whiteplains-events-bronx-books-bound-js": () => import("./../../../src/pages/whiteplains-events/bronx-books-bound.js" /* webpackChunkName: "component---src-pages-whiteplains-events-bronx-books-bound-js" */),
  "component---src-pages-whiteplains-events-dot-open-streets-js": () => import("./../../../src/pages/whiteplains-events/dot-open-streets.js" /* webpackChunkName: "component---src-pages-whiteplains-events-dot-open-streets-js" */),
  "component---src-pages-whiteplains-events-face-painting-js": () => import("./../../../src/pages/whiteplains-events/face-painting.js" /* webpackChunkName: "component---src-pages-whiteplains-events-face-painting-js" */),
  "component---src-pages-whiteplains-events-opening-day-js": () => import("./../../../src/pages/whiteplains-events/opening-day.js" /* webpackChunkName: "component---src-pages-whiteplains-events-opening-day-js" */)
}

